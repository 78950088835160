import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"cf-ray":"8e29c0e54ac39464-SJC","age":"0","vary":"Accept-Encoding","x-jsd-version-type":"version","access-control-expose-headers":"*","x-content-type-options":"nosniff","strict-transport-security":"max-age=31536000; includeSubDomains; preload","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","x-served-by":"cache-fra-etou8220096-FRA, cache-lga21979-LGA","content-type":"text/plain; charset=utf-8","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","access-control-allow-origin":"*","alt-svc":"h3=\":443\"; ma=86400","server":"cloudflare","cf-cache-status":"HIT","accept-ranges":"bytes","nel":"{\"success_fraction\":0.01,\"report_to\":\"cf-nel\",\"max_age\":604800}","timing-allow-origin":"*","report-to":"{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v4?s=GXW6XkVW%2FiR5rKU0pJqZ2izp9jxLhkZqLSs0VRSgDCFHkyREpUfVOE%2Fn%2Fntnk8e9ndivBdvUE8RL3QUrHhdkqIGRofdtOXPuU2kS%2BZ6iv86MremfD51jiuyEkM2UrQWU0ao%3D\"}],\"group\":\"cf-nel\",\"max_age\":604800}","x-jsd-version":"1.0.8","cross-origin-resource-policy":"cross-origin","x-cache":"HIT, HIT","date":"Thu, 14 Nov 2024 20:29:24 GMT"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1731616164}